.main-canvas {
  /* width: 100%; */
  /* width: 300px; */
  height: 100%;
  /* border: 1px solid grey; */
  background: #f5f5f5;
}

.canvas-container {
  height: calc(100% - 2 * 20px);
  width: calc(100% - 2 * 20px);
  /* width: 100%; */
  padding: 20px;
  /* width: 300px; */
  /* height: 100%; */
  /* max-width: 1000px; */
}

.info-text {
  font-size: 12px;
  font-family: "Roboto";
  margin: 10px 0;
  text-align: center;
  width: 100%;
  color: rgb(115, 114, 113);
}

.canvas-inner-container {
  width: 100%;

  height: 100%;
  /* height: calc(100% - 2 * 40px);
  width: calc(100% - 2 * 40px); */
}

.canvas {
  margin: 0;
  padding: 0;
  width: 100%;

  height: 100%;
}

.canvas__actions {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
}

.action-buttons {
  height: 40px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

.polygon-icon {
  opacity: 0.3 !important;
}

.small-gesture-icon {
  opacity: 0.6 !important;
}
.large-gesture-icon {
  margin-top: -7px;
  opacity: 0.45 !important;
}

.fill-line-slider {
  /* width: 230px; */
  width: 100px;
  margin: 30px auto 0 auto;
  opacity: 0.75;
}

.clear-button {
  margin-right: 5px !important;
}

.done-button {
  /* margin-right: 80px !important; */
}
