.sketch-page {
  width: 100%;
  /* width: 250px; */
  height: 100%;
  touch-action: none;
}

.title {
  font-family: "Gill Sans";
  font-size: 30px;
  /* font-weight: 600; */
}

.loading-screen {
  width: 100%;
  /* width: 250px; */
  height: 100%;
  background: white;
  text-align: center;
  padding-top: 300px;
}

.art-canvas-container {
  max-width: 800px;
  margin: auto;
  height: calc(100% - (320px + 20px + 10px));
}

.settings-container {
  height: 320px;
  padding: 30px 10px 10px 10px;
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.settings-top-container {
  display: flex;
}

.sliders-container {
  height: 120px;
  width: calc(100% - 50px);
  padding: 7px 5px 5px 5px;
}

.settings-button-container {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

.buttons-container {
  width: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-top: 5px;
}

.options-title {
  color: #555;
  text-align: center;
  font-size: 18px;
}

.settings-button {
  position: relative;
  top: -46px;
  left: 15px;
}

.dialog-option-buttons {
  display: flex;
  flex-direction: column;
  text-align: center;
}

p {
  font-size: 13px;
}

.painting {
  max-width: 60%;
}

.painting-caption {
  font-family: "Roboto";
  font-size: 10px;
}

.about-button {
  margin-top: 10px !important;
}

.reset-button {
  margin-top: 15px !important;
}

.color-mode-select {
  margin-top: 35px;
  /* margin-left: 8px; */
  height: 25px;
}

.colorpick-button {
  position: relative;
  top: 25px;

  /* margin-bottom: 15px !important; */
}

.shapepick-button {
  position: relative;
  top: 62px;
}

.stylus-mode-toggle {
  margin-top: 5px;
}

.color-preview {
  width: 18px;
  height: 18px;
  border-radius: 13px;
  margin-top: 25px;
  margin-left: 15px;
  /* position: absolute; */
  /* left: 0; */
  /* top: 0; */
}

.rgb-slider {
  margin: 8px 0;
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  /* -webkit-transition: 2s; */
  /* transition: opacity 2s; */
}

.rgb-slider:disabled {
  opacity: 0.15;
}

.rgb-slider:focus {
  outline: 0;
}

.rgb-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 25px;
  cursor: pointer;
  border-radius: 3px;
}

.red-slider::-webkit-slider-thumb {
  background: #e60404;
}

.green-slider::-webkit-slider-thumb {
  background: #019704;
}

.blue-slider::-webkit-slider-thumb {
  background: #122fb3;
}

.rgb-slider::-moz-range-thumb {
  width: 15px;
  height: 25px;
  cursor: pointer;
}

.red-slider::-moz-range-thumb {
  background: #aa0404;
}

.green-slider::-moz-range-thumb {
  background: #04aa6d;
}

.blue-slider::-moz-range-thumb {
  background: #043eaa;
}

.hue-slider {
  background-image: linear-gradient(
    to right,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );
}

.value-slider {
  background-image: linear-gradient(to right, black, white);
  border: 1px solid #555;
}

.hsv-slider::-moz-range-thumb {
  background: #ddd;
  border: 1px solid #555;
}

.hsv-slider::-webkit-slider-thumb {
  background: #ddd;
  border: 1px solid #555;
}

/* chrome picker */
.chrome-picker {
  /* position: relative; */
  margin: -12px auto 0 auto;
  position: relative;
  left: 30px;
  overflow: hidden;
  width: 300px;
  height: 160px;
  transform: scaleY(0.9);
  /* transform: scaleX(1.2); */
}

.demo-gif {
  width: 300px;
  height: 320px;
  object-fit: cover;
  object-position: -1px 0px;
}

.css-ypiqx9-MuiDialogContent-root.tutorial-content {
  padding: 0 !important;
}

/* .chrome-picker > * {
  height: 50px;
} */

/* .saturation-white {
  height: 50px;
}

.saturation-black {
  height: 50px;
} */
